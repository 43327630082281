import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Card } from "@/components/ui/card";
import { isMobile } from "react-device-detect";

export default function EarlyAccessModal() {
  const features = [
    {
      title: "private analysis",
      description: (
        <div>
          unlimited private facial analysis, <br />
          only you can see your results
        </div>
      ),
      icon: "🙈",
    },
    {
      title: "skip the queue",
      description: (
        <div>
          no more waiting in line, <br />
          your analysis is executed immediately
        </div>
      ),
      icon: "⏩",
    },
    {
      title: "track progress",
      description: (
        <div>
          see how each of your <br />
          metrics improve over time
        </div>
      ),
      icon: "📈",
    },
    {
      title: "chat with agentmaxx",
      description: (
        <div>
          chat with agentmaxx about <br />
          improving your facial attractiveness
        </div>
      ),
      icon: "💬",
    },
    {
      title: "advanced dashboard",
      description: (
        <div>
          manage your metrics and improvement
          <br /> with in-depth reports and breakdowns
        </div>
      ),
      icon: "🚀",
    },
    {
      title: "chat badge",
      description: (
        <div>
          maxx badge displayed next to <br />
          your name in chat
        </div>
      ),
      icon: "👑",
    },
  ];
  return (
    <div className="mt-5 sm:mt-12 flex justify-center">
      <Carousel
        showArrows={true}
        dynamicHeight={false}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        centerMode={true}
        width={isMobile ? "100vw" : "70vw"}
        emulateTouch={true}
      >
        {features?.map((feature, i) => (
          <div
            key={`${feature.title}-${i}`}
            className={"mx-2 max-w-full select-none"}
          >
            <Card className="p-0 sm:p-8 py-4 sm:py-8">
              <div className={"flex justify-center text-3xl sm:text-5xl"}>
                {feature.icon}
              </div>
              <div className="mt-6 sm:mt-8 flex items-center justify-center">
                <div className="text-xl sm:text-2xl mr-3">✅ </div>
                <div
                  className="text-xl sm:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-500"
                  style={{ textShadow: "0 0 10px rgba(255, 255, 255, 0.8)" }}
                >
                  {feature.title}
                </div>
              </div>
              <div className="text-center mt-4 text-sm sm:text-base sm:mt-6">
                {feature.description}
              </div>
            </Card>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

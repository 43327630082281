import { useState, useEffect } from "react";
import { Input } from "./ui/input";
import Cookies from "js-cookie";
import { ArrowRight, Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import moment from "moment";
import { Dialog, DialogContent } from "@/components/ui/dialog";

export default function Admin() {
  const [pass, setPass] = useState("");
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<any>(null); // State to track selected submission

  const key = Cookies.get("agentmaxx-key");

  const onLogin = async () => {
    if (pass) {
      Cookies.set("agentmaxx-key", pass);
      onFetchData(pass);
    }
  };

  useEffect(() => {
    (async () => {
      if (key) {
        onFetchData(key);
      }
    })();
  }, [key]);

  const onFetchData = async (auth: any) => {
    setLoading(true);
    const result = await fetch(`${process.env.REACT_APP_API_URL}/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pass: auth }),
    });
    const data = await result.json();
    if (data?.data?.submissions) {
      setData(data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (key) {
        onFetchData(key);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [key]);

  const onDeleteSubmission = async (id: any) => {
    if (window.confirm("Are you sure you want to delete this submission?")) {
      setLoading(true);
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/deleteSubmission`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pass: key, id }),
        }
      );
      setLoading(false);
      onFetchData(key);
      if (!result.ok) {
        alert("Failed to delete submission");
      }
    }
  };

  const onDeleteChatMessage = async (id: any) => {
    if (window.confirm("Are you sure you want to delete this chat message?")) {
      setLoading(true);
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/deleteChatMessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pass: key, id }),
        }
      );
      setLoading(false);
      onFetchData(key);
      if (!result.ok) {
        alert("Failed to delete chat message");
      }
    }
  };

  return (
    <div className="touch-pan-y overflow-y-scroll">
      {selectedSubmission && (
        <Dialog
          open={!!selectedSubmission}
          onOpenChange={() => setSelectedSubmission(null)}
        >
          <DialogContent className="max-w-full w-full h-full overflow-y-scroll">
            <div className="w-full h-full flex items-center justify-center overflow-y-scroll">
              <img
                src={selectedSubmission.imageUrl}
                alt={selectedSubmission.firstName}
                className="max-w-full max-h-full object-contain"
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
      {data ? (
        <div>
          {loading && (
            <Loader2 className="w-5 h-5 animate-spin fixed top-2 left-2" />
          )}
          <div className="p-8">
            <div className="mb-8">
              <Button variant="outline" onClick={() => onFetchData(key)}>
                reload
              </Button>
            </div>
            <div className="flex justify-around mb-16">
              <div>
                <div className="text-3xl sm:text-8xl black text-center">
                  {data.submissions.length}
                </div>
                <div className="text-center mt-2 text-sm sm:text-xl">
                  submissions
                </div>
              </div>
              <div>
                <div className="text-3xl sm:text-8xl black text-center">
                  {data.chatlog.length}
                </div>
                <div className="text-center mt-2 text-sm sm:text-xl">
                  chat log
                </div>
              </div>
              <div>
                <div className="text-3xl sm:text-8xl black text-center">
                  {data.earlyAccess.length}
                </div>
                <div className="text-center mt-2 text-sm sm:text-xl">
                  early access
                </div>
              </div>
            </div>
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="item-1">
                <AccordionTrigger>
                  submissions ({data.submissions.length})
                </AccordionTrigger>
                <AccordionContent className="touch-pan-y overflow-y-scroll">
                  <div className="flex flex-wrap touch-pan-y overflow-y-scroll">
                    {data.submissions
                      .sort((a: any, b: any) => {
                        return (
                          new Date(b.createdAt).getTime() -
                          new Date(a.createdAt).getTime()
                        );
                      })
                      .map((submission: any, i: any) => (
                        <div
                          key={i}
                          className="mr-8 mb-8 border p-4 cursor-pointer"
                          onClick={() => setSelectedSubmission(submission)} // Set selected submission on click
                        >
                          <div className="flex items-center">
                            <div
                              className="w-40 h-40 bg-cover bg-center"
                              style={{
                                backgroundImage: `url(${submission.imageUrl})`,
                              }}
                            />
                            <div className="ml-6 font-extrabold">
                              {submission.firstName}
                            </div>
                          </div>
                          <div className="mt-2">
                            <div>
                              submitted:{" "}
                              {moment(submission.createdAt).format(
                                "MM D YY, H:mm:ss"
                              )}{" "}
                              ({moment(submission.createdAt).fromNow()})
                            </div>
                            <div>
                              status: {submission.status}{" "}
                              {submission.status === "submitted"
                                ? "(in queue)"
                                : ""}
                            </div>
                            {submission.analysisResult && (
                              <div>
                                overall rating:{" "}
                                {submission.analysisResult.overallRating}
                              </div>
                            )}
                            {submission.crowdScore && (
                              <div>crowd score: {submission.crowdScore}</div>
                            )}
                            {submission.deleted && (
                              <div className="text-red-500">deleted</div>
                            )}
                            {!submission.deleted && (
                              <Button
                                variant="destructive"
                                onClick={() =>
                                  onDeleteSubmission(submission.id)
                                }
                                className="mt-2"
                              >
                                Delete
                              </Button>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2">
                <AccordionTrigger>
                  chat log ({data.chatlog.length})
                </AccordionTrigger>
                <AccordionContent className="touch-pan-y overflow-y-scroll">
                  {data.chatlog
                    .sort((a: any, b: any) => {
                      return (
                        new Date(b.timestamp).getTime() -
                        new Date(a.timestamp).getTime()
                      );
                    })
                    .map((chat: any, i: any) => (
                      <div key={i} className="mb-4">
                        <div className="flex flex-wrap">
                          <div className="font-black">
                            {chat.firstName} {chat.admin ? "ADMIN" : ""}
                          </div>
                          <div className="ml-2">
                            {moment(chat.timestamp).format("MM D YY, H:mm:ss")}{" "}
                            ({moment(chat.timestamp).fromNow()})
                          </div>
                        </div>
                        <div className="mt-1">{chat.content}</div>
                        {chat.deleted && (
                          <div className="text-red-500">deleted</div>
                        )}
                        {!chat.deleted && (
                          <Button
                            variant="destructive"
                            onClick={() => onDeleteChatMessage(chat.id)}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    ))}
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-3">
                <AccordionTrigger>
                  early access sign ups ({data.earlyAccess.length})
                </AccordionTrigger>
                <AccordionContent className="touch-pan-y overflow-y-scroll">
                  {data.earlyAccess
                    .sort((a: any, b: any) => {
                      return (
                        new Date(b.timestamp).getTime() -
                        new Date(a.timestamp).getTime()
                      );
                    })
                    .map((ea: any, i: any) => (
                      <div key={i} className="mb-4">
                        <div className="flex flex-wrap">
                          <div className="font-black">{ea.email}</div>
                          <div className="ml-2">
                            {moment(ea.timestamp).format("MM D YY, H:mm:ss")} (
                            {moment(ea.timestamp).fromNow()})
                          </div>
                        </div>
                      </div>
                    ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center">
          <div className="display flex items-center">
            <Input
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              type={"password"}
              //on enter submit
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onLogin();
                }
              }}
            />
            <Button
              className="ml-2"
              size={"icon"}
              onClick={onLogin}
              disabled={loading}
            >
              {loading ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <ArrowRight className="w-4 h-4" />
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

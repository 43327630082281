import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { Separator } from "@/components/ui/separator";
import { MessageSquare, MoveDiagonal } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "@/components/ui/dialog";
import { isMobile } from "react-device-detect";
import { Card } from "@/components/ui/card";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselItem, { truncated } from "@/components/carousel-item";

const categories = [
  {
    category: "skin",
    name: "skin",
    mobileName: "skin",
    emoji: "🧴",
    female: true,
    categoryTag: "skinCategoryRating",
    categoryImproveTag: "skinHowToImprove",
    subcategories: [
      {
        name: "Clear Skin",
        tag: "skinClearSkinRating",
        description: "skinClearSkinRatingDescription",
      },
      {
        name: "Even Skin Tone",
        tag: "skinEvenSkinToneRating",
        description: "skinEvenSkinToneRatingDescription",
      },
      {
        name: "Smooth Texture",
        tag: "skinSmoothTextureRating",
        description: "skinSmoothTextureRatingDescription",
      },
      {
        name: "Healthy Glow",
        tag: "skinHealthyGlowRating",
        description: "skinHealthyGlowRatingDescription",
      },
    ],
  },
  {
    category: "eyes",
    name: "eyes",
    mobileName: "eyes",
    emoji: "👁️",
    female: true,
    categoryTag: "eyesCategoryRating",
    categoryImproveTag: "eyesHowToImprove",
    subcategories: [
      {
        name: "Symmetry",
        tag: "eyesSymmetryRating",
        description: "eyesSymmetryRatingDescription",
      },
      {
        name: "Bright Eyes",
        tag: "eyesBrightEyesRating",
        description: "eyesBrightEyesRatingDescription",
      },
      {
        name: "Well-Groomed Eyebrows",
        tag: "eyesWellGroomedEyebrowsRating",
        description: "eyesWellGroomedEyebrowsRatingDescription",
      },
      {
        name: "No Dark Circles",
        tag: "eyesNoDarkCirclesRating",
        description: "eyesNoDarkCirclesRatingDescription",
      },
      {
        name: "Long Thick Eyelashes",
        tag: "eyesLongThickEyelashesRating",
        description: "eyesLongThickEyelashesRatingDescription",
      },
    ],
  },
  {
    category: "jawline",
    name: "jaw",
    mobileName: "jaw",
    emoji: "👨",
    female: true,
    categoryTag: "jawlineAndChinCategoryRating",
    categoryImproveTag: "jawlineAndChinHowToImprove",
    subcategories: [
      {
        name: "Strong Jawline",
        tag: "jawlineStrongJawlineRating",
        description: "jawlineStrongJawlineRatingDescription",
      },
      {
        name: "Symmetrical Chin",
        tag: "jawlineSymmetricalChinRating",
        description: "jawlineSymmetricalChinRatingDescription",
      },
      {
        name: "No Double Chin",
        tag: "jawlineNoDoubleChinRating",
        description: "jawlineNoDoubleChinRatingDescription",
      },
    ],
  },
  {
    category: "smile",
    name: "smile",
    mobileName: "smile",
    emoji: "😄",
    female: true,
    categoryTag: "teethAndSmileCategoryRating",
    categoryImproveTag: "teethAndSmileHowToImprove",
    subcategories: [
      {
        name: "White Teeth",
        tag: "teethWhiteTeethRating",
        description: "teethWhiteTeethRatingDescription",
      },
      {
        name: "Straight Teeth",
        tag: "teethStraightTeethRating",
        description: "teethStraightTeethRatingDescription",
      },
      {
        name: "Gum Health",
        tag: "teethGumHealthRating",
        description: "teethGumHealthRatingDescription",
      },
      {
        name: "Full Lips",
        tag: "teethFullLipsRating",
        description: "teethFullLipsRatingDescription",
      },
    ],
  },
  {
    category: "nose",
    name: "nose",
    mobileName: "nose",
    emoji: "👃",
    female: true,
    categoryTag: "noseCategoryRating",
    categoryImproveTag: "noseHowToImprove",
    subcategories: [
      {
        name: "Symmetry",
        tag: "noseSymmetryRating",
        description: "noseSymmetryRatingDescription",
      },
      {
        name: "Straight Nose Bridge",
        tag: "noseStraightNoseBridgeRating",
        description: "noseStraightNoseBridgeRatingDescription",
      },
      {
        name: "Proportional Size",
        tag: "noseProportionalSizeRating",
        description: "noseProportionalSizeRatingDescription",
      },
    ],
  },
  {
    category: "hair",
    name: "hair",
    mobileName: "hair",
    emoji: "💇‍♂️",
    female: true,
    categoryTag: "hairCategoryRating",
    categoryImproveTag: "hairHowToImprove",
    subcategories: [
      {
        name: "Healthy Hair",
        tag: "hairHealthyHairRating",
        description: "hairHealthyHairRatingDescription",
      },
      {
        name: "Hairline",
        tag: "hairHairlineRating",
        description: "hairHairlineRatingDescription",
      },
      {
        name: "Face Framing Style",
        tag: "hairFaceFramingStyleRating",
        description: "hairFaceFramingStyleRatingDescription",
      },
    ],
  },
  {
    category: "symmetry",
    name: "facial symmetry",
    mobileName: "balance",
    emoji: "⚖️",
    female: true,
    categoryTag: "facialSymmetryCategoryRating",
    categoryImproveTag: "facialSymmetryHowToImprove",
    subcategories: [
      {
        name: "Balanced Features",
        tag: "facialSymmetryBalancedFeaturesRating",
        description: "facialSymmetryBalancedFeaturesRatingDescription",
      },
      {
        name: "Golden Ratio",
        tag: "facialSymmetryGoldenRatioRating",
        description: "facialSymmetryGoldenRatioRatingDescription",
      },
    ],
  },
  {
    category: "cheekbones",
    name: "cheekbones",
    mobileName: "cheeks",
    emoji: "🦴",
    female: true,
    categoryTag: "cheekbonesCategoryRating",
    categoryImproveTag: "cheekbonesHowToImprove",
    subcategories: [
      {
        name: "High Cheekbones",
        tag: "cheekbonesHighCheekbonesRating",
        description: "cheekbonesHighCheekbonesRatingDescription",
      },
      {
        name: "Slightly Hollowed Cheeks",
        tag: "cheekbonesSlightlyHollowedCheeksRating",
        description: "cheekbonesSlightlyHollowedCheeksRatingDescription",
      },
    ],
  },
  {
    category: "facialHair",
    name: "facial hair",
    mobileName: "groom",
    emoji: "🧔",
    female: false,
    categoryTag: "facialHairCategoryRating",
    categoryImproveTag: "facialHairHowToImprove",
    subcategories: [
      {
        name: "Well-Groomed",
        tag: "facialHairWellGroomedRating",
        description: "facialHairWellGroomedRatingDescription",
      },
      {
        name: "Complementary To Face Shape",
        tag: "facialHairComplementaryToFaceShapeRating",
        description: "facialHairComplementaryToFaceShapeRatingDescription",
      },
    ],
  },
  {
    category: "bodyFatAndMuscleTone",
    name: "body",
    mobileName: "body",
    emoji: "💪",
    female: true,
    categoryTag: "bodyFatAndMuscleToneCategoryRating",
    categoryImproveTag: "bodyFatAndMuscleToneHowToImprove",
    subcategories: [
      {
        name: "Low Body Fat Percentage",
        tag: "bodyFatLowBodyFatPercentageRating",
        description: "bodyFatLowBodyFatPercentageRatingDescription",
      },
      {
        name: "Toned Muscles",
        tag: "bodyFatTonedMusclesRating",
        description: "bodyFatTonedMusclesRatingDescription",
      },
    ],
  },
  {
    category: "ears",
    name: "ears",
    mobileName: "ears",
    emoji: "👂",
    female: true,
    categoryTag: "earsCategoryRating",
    categoryImproveTag: "earsHowToImprove",
    subcategories: [
      {
        name: "Symmetrical Ears",
        tag: "earsSymmetricalEarsRating",
        description: "earsSymmetricalEarsRatingDescription",
      },
      {
        name: "Proper Size",
        tag: "earsProperSizeRating",
        description: "earsProperSizeRatingDescription",
      },
    ],
  },
  {
    category: "harmony",
    name: "harmony",
    mobileName: "harmony",
    emoji: "🎶",
    female: true,
    categoryTag: "facialHarmonyCategoryRating",
    categoryImproveTag: "facialHarmonyHowToImprove",
    subcategories: [
      {
        name: "Balanced Proportions",
        tag: "facialHarmonyBalancedProportionsRating",
        description: "facialHarmonyBalancedProportionsRatingDescription",
      },
      {
        name: "Youthful Appearance",
        tag: "facialHarmonyYouthfulAppearanceRating",
        description: "facialHarmonyYouthfulAppearanceRatingDescription",
      },
    ],
  },
  {
    category: "overall",
    name: "overall",
    mobileName: "overall",
    emoji: "✨",
    female: true,
    categoryTag: "overallRating",
    categoryImproveTag: "overallHowToImprove",
  },
];

export default function Results({
  currentAnalysis,
  result,
  showMobileChat,
  setShowMobileChat,
  queue,
  votingTimeLimit,
  setVotingTimeLimit,
  currentVotes,
  onlineUsersCount,
  unreadMessagesCount,
}: {
  currentAnalysis: any;
  result: any;
  showMobileChat: boolean;
  setShowMobileChat: any;
  queue: any;
  votingTimeLimit: any;
  setVotingTimeLimit: any;
  currentVotes: any;
  onlineUsersCount: any;
  unreadMessagesCount: any;
}) {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const [timeLeft, setTimeLeft] = useState(result?.analysisLength); // Start with the provided analysisLength in milliseconds

  useEffect(() => {
    const startTime = new Date(result.timestamp).getTime(); // Use the provided timestamp to determine the start
    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime; // Calculate elapsed time in milliseconds
      const remainingTime = result?.analysisLength - elapsedTime; // Calculate the remaining time in milliseconds
      setTimeLeft(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(interval); // Stop the countdown when it reaches zero
      }
      if (remainingTime <= 10000 && !votingTimeLimit) {
        setVotingTimeLimit(true);
      }
    }, 100); // Update every 100ms for smoother progress bar movement

    return () => clearInterval(interval); // Clean up the interval when the component unmounts
  }, [
    result?.analysisLength,
    result?.timestamp,
    votingTimeLimit,
    setVotingTimeLimit,
  ]);

  const progressPercentage = (timeLeft / result?.analysisLength) * 100;

  useEffect(() => {
    setCurrentCategoryIndex(0);
    setShowMobileChat(false);
    setVotingTimeLimit(false);
    //eslint-disable-next-line
  }, [currentAnalysis]);

  useEffect(() => {
    setTimeLeft(result?.analysisLength);
    setShowMobileChat(false);
    //eslint-disable-next-line
  }, [result]);

  const handleNext = () => {
    if (currentCategoryIndex < currentAnalysisCategories.length - 1) {
      setCurrentCategoryIndex(currentCategoryIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentCategoryIndex > 0) {
      setCurrentCategoryIndex(currentCategoryIndex - 1);
    }
  };

  let currentAnalysisCategories = result?.isFemale
    ? categories.filter((category) => category.female)
    : categories;

  const currentCategory = currentAnalysisCategories[currentCategoryIndex];

  // Extract votes into an array
  const votesArray = currentVotes ? Object.values(currentVotes) : [];

  // Calculate the sum of votes
  const sumVotes: any = votesArray.reduce((a: any, b: any) => a + b, 0);

  // Calculate the crowd vote (average)
  const crowdVote =
    votesArray.length > 0 ? (sumVotes / votesArray.length).toFixed(1) : null;

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogDescription>
              <div className="flex items-center">
                <div className="text-3xl mr-3">{currentCategory.emoji}</div>
                <div className="text-3xl mr-3">
                  {currentCategory.name} rating:
                </div>
                <div className="text-3xl">
                  {result?.[currentCategory.categoryTag]}/10
                </div>
              </div>
              <div className="mt-4">
                <span className="font-black">how to improve:</span>{" "}
                {result?.[currentCategory.categoryImproveTag]
                  ?.toLowerCase()
                  .replace(/^[.\s]+|[.\s]+$/g, "")}
              </div>
              {currentCategory.subcategories?.length &&
                currentCategory.subcategories?.length > 0 && (
                  <>
                    <Separator className="my-6" />
                    <div>
                      {currentCategory.subcategories.map(
                        (subcategory, index) => (
                          <div key={index} className="mb-6">
                            <div className="font-extrabold text-xl">
                              {subcategory.name?.toLowerCase()}:{" "}
                              {result?.[subcategory.tag]}/10
                            </div>

                            <div className="mt-2">
                              {result?.[subcategory.description]
                                ?.toLowerCase()
                                .replace(/^[.\s]+|[.\s]+$/g, "")}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <div className="w-full h-full">
        <div className="flex flex-col h-full">
          {/* Background image div */}
          <div className="relative flex-grow">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: `url(${currentAnalysis?.imageUrl})`,
              }}
            ></div>
            <div className="absolute top-0 left-0 sm:top-0 sm:left-0 p-1 sm:p-1">
              <div
                className="text-[12px] sm:text-[15px] font-bold sm:font-extrabold relative flex items-center"
                style={{ textShadow: "0 0 10px rgba(0, 0, 0, 0.8)" }}
              >
                <div className="ml-1.5 mr-2 sm:ml-2 sm:mr-3 relative">
                  <span className="absolute top-[-2.7px] left-[-3px] sm:top-[-4px] sm:left-[-4px] flex h-1.5 w-1.5 sm:h-2 sm:w-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-300 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-1.5 w-1.5 sm:h-2 sm:w-2 bg-red-600"></span>
                  </span>
                </div>
                LIVE
              </div>
            </div>
            <div className="absolute top-0 left-0 w-full flex justify-center">
              <div className="pt-[2.5px] pb-[4px] px-2 bg-white bg-opacity-20 backdrop-blur-lg rounded-b-lg">
                <div
                  className="text-[13px] sm:text-[18px] font-bold relative flex items-center"
                  style={{ textShadow: "0 0 10px rgba(0, 0, 0, 0.8)" }}
                >
                  viewing {currentAnalysis?.firstName}'s analysis
                </div>
              </div>
            </div>
            {crowdVote && (
              <div className="absolute top-0 right-0 p-0 pr-1 pt-[1.5px] sm:pt-[3px] sm:pr-2">
                <div
                  className="font-bold sm:font-extrabold relative flex items-center"
                  style={{ textShadow: "0 0 10px rgba(0, 0, 0, 0.8)" }}
                >
                  <div className="text-right">
                    <div className="h-[19px] sm:h-[25px] text-[16px] sm:text-[20px]">
                      {crowdVote}
                    </div>
                    <div className="text-zinc-300 text-[9px] sm:text-[10px]">
                      <div className="h-2.5">avg.</div>
                      <div>crowd vote</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*<div className="absolute top-0 left-0 sm:top-2 sm:left-2 p-1 sm:p-2 bg-black bg-opacity-40 sm:bg-opacity-50">
              <div className="text-[16px] sm:text-[18px] font-bold sm:font-extrabold relative flex items-center">
                live
                <div className="mx-2 sm:mx-3 relative">
                  <span className="absolute top-[-3px] left-[-4px] sm:top-[-5px] sm:left-[-5px] flex h-2 w-2 sm:h-2.5 sm:w-2.5">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 sm:h-2.5 sm:w-2.5 bg-red-500"></span>
                  </span>
                </div>
                viewing {currentAnalysis?.firstName}'s analysis
              </div>
            </div>*/}
            {/*queue && queue.length > 0 && (
              <div className="block sm:hidden absolute top-0 right-0 p-0.5 bg-black bg-opacity-40 sm:bg-opacity-50">
                <div className="text-[11px] flex items-center">
                  <Users className="w-2.5 h-2.5 mr-1" /> {queue.length} in queue
                </div>
              </div>
            )*/}
            <div className="absolute top-auto sm:top-[51px] bottom-3 sm:bottom-auto left-0 sm:top-2 sm:left-2 p-1 sm:p-2 sm:pt-2 bg-black bg-opacity-40 sm:bg-opacity-50">
              {currentAnalysisCategories.map((category, index) => (
                <div
                  key={`${category.categoryTag}-${index}`}
                  className={`flex justify-between items-center text-[12px] sm:text-[14px] ${
                    index === categories.length - 1 ? "" : "mb-0 sm:mb-1"
                  }`}
                >
                  <div className="hidden sm:block mr-3">
                    {category.emoji}{" "}
                    {category.name === "facial symmetry"
                      ? "symmetry"
                      : category.name}
                  </div>
                  <div className="block sm:hidden mr-1">
                    {category.emoji} {category.mobileName}
                  </div>
                  <div>{result?.[category.categoryTag]}/10</div>
                </div>
              ))}
            </div>
            <div className="block sm:hidden absolute bottom-3 right-1">
              {onlineUsersCount && onlineUsersCount > 0 ? (
                <div
                  className="absolute top-[-16px] left-0 w-full flex sm:hidden justify-center items-center font-bold text-[9.5px]"
                  style={{ textShadow: "0 0 10px rgba(0, 0, 0, 0.8)" }}
                >
                  <div className="rounded-full h-1 w-1 bg-green-500 mr-1" />
                  {onlineUsersCount} in chat
                </div>
              ) : (
                <></>
              )}
              <Button
                variant="outline"
                className="bg-black bg-opacity-70 h-9 w-32 relative"
                onClick={() => setShowMobileChat(!showMobileChat)}
              >
                chat <MessageSquare fill="white" className="w-4 h-4 ml-2" />
                {unreadMessagesCount && unreadMessagesCount > 0 ? (
                  <div className="py-0 px-1.5 bg-red-500 font-bold text-white text-[10px] absolute top-[-8px] right-[-4px] rounded-full">
                    {unreadMessagesCount > 99 ? "99+" : unreadMessagesCount}
                  </div>
                ) : (
                  <></>
                )}
              </Button>
            </div>
            <div
              className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black opacity-100"
              style={{ top: isMobile ? "95%" : "50%" }}
            ></div>
          </div>
          <div className="flex-grow-0 pt-3 sm:pt-8">
            <div className="block sm:hidden">
              <Carousel
                showArrows={false}
                dynamicHeight={false}
                showIndicators={false}
                showThumbs={false}
                showStatus={false}
                centerMode={true}
              >
                {currentAnalysisCategories?.map((category, i) => (
                  <div key={`${category.name}-${i}`}>
                    <CarouselItem currentCategory={category} result={result} />
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="hidden sm:flex justify-between items-center">
              {currentCategoryIndex > 0 ? (
                <Card
                  onClick={handlePrevious}
                  className="p-4 h-full cursor-pointer transition-all hover:bg-zinc-900 relative select-none"
                >
                  <div>
                    {currentAnalysisCategories[currentCategoryIndex - 1].emoji}
                  </div>
                  <div className="absolute left-0 bottom-[-24px] w-full text-center text-sm">
                    {
                      currentAnalysisCategories[currentCategoryIndex - 1]
                        .mobileName
                    }
                  </div>
                </Card>
              ) : (
                <div />
              )}
              <Card
                className={`${
                  currentCategoryIndex === 0
                    ? "mr-6"
                    : currentCategoryIndex ===
                      currentAnalysisCategories.length - 1
                    ? "ml-6"
                    : "mx-6"
                } p-4 w-full cursor-pointer hover:bg-zinc-900 transition-all relative`}
                onClick={() => !open && setOpen(true)}
              >
                <div className="flex justify-center sm:justify-between items-center">
                  <div className="flex items-center font-bold sm:font-normal">
                    <div className="text-2xl sm:text-3xl mr-3">
                      {currentCategory.emoji}
                    </div>
                    <div className="hidden sm:block text-xl sm:text-3xl mr-3">
                      {currentCategory.name} rating:
                    </div>
                    <div className="block sm:hidden text-xl sm:text-3xl mr-3">
                      {currentCategory.mobileName} rating:
                    </div>
                    <div className="text-xl sm:text-3xl">
                      {result?.[currentCategory.categoryTag]}/10
                    </div>
                  </div>
                  <div className="hidden sm:flex flex justify-end">
                    <MoveDiagonal className="w-6 h-6 ml-2" />
                  </div>
                </div>

                <div className="hidden sm:block mt-4">
                  <span className="font-black">how to improve:</span>{" "}
                  {truncated(result?.[currentCategory.categoryImproveTag], 100)}
                </div>
              </Card>

              {currentCategoryIndex < currentAnalysisCategories.length - 1 ? (
                <Card
                  onClick={handleNext}
                  className="p-4 h-full cursor-pointer transition-all hover:bg-zinc-900 relative select-none"
                >
                  <span className="absolute top-[-5px] right-[-5px] flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                  </span>
                  <div>
                    {currentAnalysisCategories[currentCategoryIndex + 1].emoji}
                  </div>
                  <div className="absolute bottom-[-24px] left-0 w-full text-center text-sm">
                    {
                      currentAnalysisCategories[currentCategoryIndex + 1]
                        .mobileName
                    }
                  </div>
                </Card>
              ) : (
                <div />
              )}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center mt-8 relative">
            <div className="absolute top-[-20px] left-0 text-[12px]">
              {timeLeft && (
                <div>next analysis in {Math.round(timeLeft / 1000)}s</div>
              )}
            </div>
            {queue && queue.length > 0 && (
              <div className="block sm:hidden absolute top-[-20px] right-0 text-[12px]">
                ({queue.length}) in queue
              </div>
            )}
            <div className="w-full h-[1px] overflow-hidden">
              <div
                style={{ width: `${progressPercentage}%` }}
                className="h-full bg-white transition-all duration-500"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Card } from "@/components/ui/card";
import { MoveDiagonal } from "lucide-react";
import { Separator } from "@/components/ui/separator";

export const truncated = (text: any, length: any) => {
  const maxLength = length; // Assuming maxLength is 60

  const cleanedText = text?.toLowerCase().replace(/^[.\s]+|[.\s]+$/g, "");

  if (!cleanedText) return null;

  const truncatedText =
    cleanedText.length > maxLength ? (
      <>
        {cleanedText.substring(0, maxLength)}
        <strong>
          ... <u>read more</u>
        </strong>
      </>
    ) : (
      <>
        {cleanedText}
        <strong>
          {" "}
          <u>read more</u>
        </strong>
      </>
    );

  return truncatedText;
};

export default function CarouselItem({
  currentCategory,
  result,
}: {
  currentCategory: any;
  result: any;
}) {
  return (
    <Dialog>
      <DialogTrigger>
        <Card className="p-2 mx-1">
          <div className="flex justify-between sm:justify-between items-center">
            <div className="flex items-center font-bold sm:font-normal">
              <div className="text-xl sm:text-3xl mr-2 sm:mr-3">
                {currentCategory.emoji}
              </div>
              <div className="hidden sm:block text-lg sm:text-3xl mr-2 sm:mr-3">
                {currentCategory.name} rating:
              </div>
              <div className="block sm:hidden text-lg sm:text-3xl mr-2 sm:mr-3">
                {currentCategory.mobileName} rating:
              </div>
              <div className="text-lg sm:text-3xl">
                {result?.[currentCategory.categoryTag]}/10
              </div>
            </div>
            <div>
              <MoveDiagonal className="w-5 h-5" />
            </div>
          </div>
          <div className="mt-2 text-left text-sm">
            <span className="font-extrabold">how to improve:</span>{" "}
            {truncated(result?.[currentCategory.categoryImproveTag], 60)}
          </div>
        </Card>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh]">
        <DialogHeader>
          <DialogDescription className="text-left">
            <div className="flex items-center">
              <div className="text-2xl sm:text-3xl mr-3">
                {currentCategory.emoji}
              </div>
              <div className="text-2xl sm:text-3xl mr-3">
                {currentCategory.name} rating:
              </div>
              <div className="text-2xl sm:text-3xl">
                {result?.[currentCategory.categoryTag]}/10
              </div>
            </div>
            <div className="mt-4">
              <span className="font-black">how to improve:</span>{" "}
              {result?.[currentCategory.categoryImproveTag]
                ?.toLowerCase()
                .replace(/^[.\s]+|[.\s]+$/g, "")}
            </div>
            {currentCategory.subcategories?.length &&
              currentCategory.subcategories?.length > 0 && (
                <>
                  <Separator className="my-4 sm:my-6" />
                  <div>
                    {currentCategory.subcategories.map(
                      (subcategory: any, index: any) => (
                        <div
                          key={index}
                          className={`${
                            index === currentCategory.subcategories.length - 1
                              ? ""
                              : "mb-4 sm:mb-6"
                          }`}
                        >
                          <div className="font-extrabold text-lg sm:text-xl">
                            {subcategory.name?.toLowerCase()}:{" "}
                            {result?.[subcategory.tag]}/10
                          </div>

                          <div className="mt-1 sm:mt-2">
                            {result?.[subcategory.description]
                              ?.toLowerCase()
                              .replace(/^[.\s]+|[.\s]+$/g, "")}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

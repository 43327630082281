import { useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { isMobile } from "react-device-detect";
import { X } from "lucide-react";
import { toast } from "sonner";

export default function Chat({
  messages,
  imageUrl,
  firstName,
  sendMessage,
  inputValue,
  setInputValue,
  mobile,
  setShowMobileChat,
  currentVotes,
  submissionId,
  currentAnalysis,
  status,
  onSubmitVote,
  votingTimeLimit,
  onlineUsersCount,
}: {
  messages: any[];
  imageUrl: any;
  firstName: any;
  sendMessage: any;
  inputValue: any;
  setInputValue: any;
  mobile?: any;
  setShowMobileChat?: any;
  currentVotes: any;
  submissionId: any;
  currentAnalysis: any;
  status: any;
  onSubmitVote: any;
  votingTimeLimit: any;
  onlineUsersCount: any;
}) {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      //@ts-ignore
      messagesEndRef?.current?.scrollIntoView();
    }
  }, [messages]);

  const disabled = !firstName || !imageUrl;

  const hasVoted = currentVotes && currentVotes[submissionId] !== undefined;
  const userVote = currentVotes && currentVotes[submissionId];
  const isLive = status === "live";
  const isNotAnalyzedUser =
    currentAnalysis && submissionId !== currentAnalysis.id;
  const canVote = isLive && isNotAnalyzedUser && !hasVoted && submissionId;

  // Extract votes into an array
  const votesArray = currentVotes ? Object.values(currentVotes) : [];

  // Calculate the sum of votes
  const sumVotes: any = votesArray.reduce((a: any, b: any) => a + b, 0);

  // Calculate the crowd vote (average)
  const crowdVote =
    votesArray.length > 0 ? Math.round(sumVotes / votesArray.length) : null;

  return (
    <div
      className={
        isMobile && mobile
          ? "fixed top-0 right-0 w-[65vw] h-[80vh] bg-black z-50 opacity-95 border-l-4 border-b-4 overflow-hidden"
          : !mobile
          ? "hidden sm:flex w-[300px] md:w-[300px] xl:w-[350px] h-full p-6 sm:pt-2 md:pt-2 md:p-6 xl:p-8 xl:pt-2 flex flex-col relative"
          : "hidden"
      }
    >
      {messages.length > 5 && !mobile && (
        <div className="absolute inset-x-0 top-0 h-40 bg-gradient-to-b from-black to-transparent z-10 pointer-events-none"></div>
      )}
      <div className={`h-full ${isMobile ? "p-2" : ""} flex flex-col relative`}>
        {mobile && (
          <Button
            className="absolute top-3.5 right-2 px-2 w-8 h-8 opacity-70"
            onClick={() => setShowMobileChat(false)}
          >
            <X className="w-5 h-5" />
          </Button>
        )}
        <div
          className={`flex-1 overflow-y-auto touch-pan-y mb-2 sm:mb-4 overscroll-contain`}
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`bg-transparent text-white p-1 sm:p-2 my-0.5 sm:my-1 rounded flex items-start ${
                message.admin ? "border-l-2" : ""
              } border-zinc-500`}
            >
              <div
                className="w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-center bg-cover mr-3 shrink-0 mt-2"
                style={{ backgroundImage: `url(${message.imageUrl})` }}
              />
              <div>
                <div className="text-sm sm:text-base">
                  <strong>{message.firstName}</strong>
                </div>
                <div className="text-[13px] sm:text-[14px]">
                  {message.content.toLowerCase()}
                </div>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef}></div>
        </div>
        {isLive &&
          submissionId === currentAnalysis?.id &&
          votingTimeLimit &&
          crowdVote !== null && (
            <div className="bg-gray-800 p-2 rounded mb-2">
              <p className="text-center text-[14px] lg:text-[14px] xl:text-[16px] font-bold">
                🎉 the crowd voted you {crowdVote}/10
              </p>
            </div>
          )}
        {isLive && isNotAnalyzedUser && (
          <div className="bg-gray-800 p-2 rounded mb-2">
            {votingTimeLimit && crowdVote !== null ? (
              <p className="text-center text-[14px] lg:text-[14px] xl:text-[16px] font-bold">
                🎉 the crowd voted {currentAnalysis.firstName} {crowdVote}/10
              </p>
            ) : hasVoted ? (
              <p className="text-center text-[14px] lg:text-[14px] xl:text-[16px] font-bold">
                you voted {currentAnalysis.firstName} {userVote}/10
              </p>
            ) : (
              !votingTimeLimit && (
                <>
                  <p className="text-[14px] lg:text-[14px] xl:text-[16px] font-bold mb-2">
                    what would you analyze <br />
                    {currentAnalysis.firstName} 1-10?
                  </p>
                  <div className="flex justify-between">
                    {[1, 2, 3, 4, 5].map((number) => (
                      <Button
                        key={number}
                        onClick={() => {
                          if (canVote) {
                            onSubmitVote(number);
                          } else if (!submissionId) {
                            toast.error("get analyzed to unlock voting");
                          } else {
                            toast.error("you can't vote at the moment");
                          }
                        }}
                        variant="outline"
                      >
                        {number}
                      </Button>
                    ))}
                  </div>
                  <div className="flex justify-between mt-1">
                    {[6, 7, 8, 9, 10].map((number) => (
                      <Button
                        key={number}
                        onClick={() => {
                          if (canVote) {
                            onSubmitVote(number);
                          } else if (!submissionId) {
                            toast.error("get analyzed to unlock voting");
                          } else {
                            toast.error("you can't vote at the moment");
                          }
                        }}
                        variant="outline"
                      >
                        {number}
                      </Button>
                    ))}
                  </div>
                </>
              )
            )}
          </div>
        )}
        <div className="flex items-center">
          <div
            className="w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-cover bg-center mr-2 shrink-0"
            style={{
              backgroundImage: disabled
                ? `url(/agentmaxx-logo-transparent.png)`
                : `url(${imageUrl})`,
            }}
          />
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
            maxLength={1000}
            placeholder="type your message..."
            className="bg-transparent text-white p-2 rounded flex-1 text-base outline-none"
          />
        </div>
        <div className="relative w-full">
          <Button
            onClick={sendMessage}
            disabled={disabled}
            className="font-bold h-[30px] sm:h-[40px] w-full sm:font-extrabold mt-2 px-0.5 py-0.5 sm:px-2 sm:py-4 text-sm sm:text-base"
          >
            {disabled ? "get analyzed to unlock chat" : "send"}
          </Button>
          {onlineUsersCount && onlineUsersCount > 0 ? (
            <div className="absolute bottom-[-24px] left-0 w-full hidden sm:flex justify-center items-center font-bold text-[11px]">
              <div className="rounded-full h-1.5 w-1.5 bg-green-500 mr-1.5" />
              {onlineUsersCount} {onlineUsersCount === 1 ? "person" : "people"}{" "}
              in chat
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

import { Button } from "@/components/ui/button";

export default function HomeAnimation() {
  return (
    <>
      <div className="absolute top-0 left-24">
        <Button
          variant="outline"
          className="hover:bg-black hover:text-current hover:cursor-default hover:border-current animate-slide-fade-right opacity-0"
        >
          🧴 skin 7/10
        </Button>
      </div>
      <div className="absolute top-40 left-48">
        <Button
          variant="outline"
          className="hover:bg-black hover:text-current hover:cursor-default hover:border-current animate-slide-fade-left animation-delay-3s opacity-0"
        >
          👁️ eyes 9/10
        </Button>
      </div>
      <div className="absolute top-12 right-32">
        <Button
          variant="outline"
          className="hover:bg-black hover:text-current hover:cursor-default hover:border-current animate-slide-fade-right animation-delay-3s opacity-0"
        >
          🦴 cheekbones 8/10
        </Button>
      </div>
      <div className="absolute top-48 right-16">
        <Button
          variant="outline"
          className="hover:bg-black hover:text-current hover:cursor-default hover:border-current animate-slide-fade-left animation-delay-8s opacity-0"
        >
          ✨ overall 9/10
        </Button>
      </div>
    </>
  );
}

import { Toaster } from "sonner";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "@/components/home";
import ReactGA from "react-ga4";
import Admin from "@/components/admin";

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_GA) {
  ReactGA.initialize(process.env.REACT_APP_GA);
}

function App() {
  return (
    <div>
      <Toaster
        position="bottom-center"
        theme="dark"
        toastOptions={{
          unstyled: false,
          classNames: {
            error: "bg-red-400",
            success: "text-green-400",
            warning: "text-yellow-400",
            info: "bg-blue-400",
          },
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/earlyaccess" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

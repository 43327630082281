import { Button } from "@/components/ui/button";
import SelfieModal from "@/components/selfie-modal";
import { useState, useEffect, useRef } from "react";
import { socket } from "@/socket";
import { toast } from "sonner";
import Cookies from "js-cookie";
import {
  ArrowRightCircle,
  Loader2,
  MessageSquare,
  ScanFaceIcon,
} from "lucide-react";
import Chat from "@/components/chat";
import Results from "@/components/results";
import Queue from "@/components/queue";
import { Badge } from "./ui/badge";
import useOutsideAlerter from "@/lib/outsideAlerter";
import { Link } from "react-router-dom";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import EarlyAccessModal from "./early-access-carousel";
import { isMobile } from "react-device-detect";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import HomeAnimation from "@/components/home-animation";

const tempResults = {
  skinCategoryRating: "7",
  skinClearSkinRating: "8",
  skinClearSkinRatingDescription:
    "The skin appears mostly clear with minimal blemishes.",
  skinEvenSkinToneRating: "7",
  skinEvenSkinToneRatingDescription:
    "The skin has a fairly even tone though some redness is present.",
  skinSmoothTextureRating: "8",
  skinSmoothTextureRatingDescription:
    "Skin texture appears smooth, with minimal visible pores and fine lines.",
  skinHealthyGlowRating: "7",
  skinHealthyGlowRatingDescription:
    "The skin looks relatively well-hydrated, but a bit more vibrancy could enhance the overall glow.",
  skinHowToImprove:
    "Maintain a consistent skincare routine including cleansing, moisturizing, and the use of serums containing Vitamin C and hyaluronic acid.",
  eyesCategoryRating: "7",
  eyesSymmetryRating: "8",
  eyesSymmetryRatingDescription: "The eyes are evenly aligned and symmetrical.",
  eyesBrightEyesRating: "8",
  eyesBrightEyesRatingDescription: "The eyes appear bright and clear.",
  eyesWellGroomedEyebrowsRating: "6",
  eyesWellGroomedEyebrowsRatingDescription:
    "Eyebrows could benefit from a bit more grooming to enhance facial symmetry.",
  eyesNoDarkCirclesRating: "8",
  eyesNoDarkCirclesRatingDescription:
    "Minimal dark circles visible under eyes.",
  eyesLongThickEyelashesRating: "6",
  eyesLongThickEyelashesRatingDescription:
    "Eyelashes could be longer and thicker for an enhanced appearance.",
  eyesHowToImprove:
    "Consider using eye creams to maintain brightness and reduce puffiness, and use an eyebrow pencil or powder for better grooming. Use a lash growth serum.",
  jawlineAndChinCategoryRating: "8",
  jawlineStrongJawlineRating: "9",
  jawlineStrongJawlineRatingDescription:
    "The jawline is strong and well-defined.",
  jawlineSymmetricalChinRating: "8",
  jawlineSymmetricalChinRatingDescription:
    "The chin appears symmetrical and proportional to the face.",
  jawlineNoDoubleChinRating: "9",
  jawlineNoDoubleChinRatingDescription:
    "No visible double chin; neck area is lean.",
  jawlineAndChinHowToImprove:
    "Maintain a healthy diet and exercise regimen. Facial exercises can further enhance jawline definition.",
  teethAndSmileCategoryRating: "7",
  teethWhiteTeethRating: "8",
  teethWhiteTeethRatingDescription: "Teeth appear white and clean.",
  teethStraightTeethRating: "7",
  teethStraightTeethRatingDescription:
    "Teeth are relatively straight but may benefit from minor alignment improvements.",
  teethGumHealthRating: "8",
  teethGumHealthRatingDescription:
    "Gums appear healthy with no excessive gum show.",
  teethFullLipsRating: "6",
  teethFullLipsRatingDescription:
    "Lips are well-defined but could be slightly fuller.",
  teethAndSmileHowToImprove:
    "Regular dental check-ups and cleanings, use whitening toothpaste, and consider orthodontics for improved teeth alignment. Use lip balm or lip plumper for fuller lips.",
  noseCategoryRating: "7",
  noseSymmetryRating: "7",
  noseSymmetryRatingDescription:
    "Nose is mostly symmetrical with minimal deviations.",
  noseStraightNoseBridgeRating: "7",
  noseStraightNoseBridgeRatingDescription:
    "Nose bridge is fairly straight, with slight variations.",
  noseProportionalSizeRating: "8",
  noseProportionalSizeRatingDescription:
    "The size of the nose is proportional to the face.",
  noseHowToImprove:
    "No major improvements needed; however, consider cosmetic treatments for a more defined appearance if desired.",
  hairCategoryRating: "8",
  hairHealthyHairRating: "8",
  hairHealthyHairRatingDescription:
    "Hair looks healthy, shiny, and well-maintained.",
  hairHairlineRating: "7",
  hairHairlineRatingDescription: "Hairline is even with minimal recession.",
  hairFaceFramingStyleRating: "7",
  hairFaceFramingStyleRatingDescription:
    "Haircut suits the face well, framing the features nicely.",
  hairHowToImprove:
    "Regular trims, using quality hair care products, and maintaining a healthy diet rich in vitamins essential for hair growth.",
  facialSymmetryCategoryRating: "8",
  facialSymmetryBalancedFeaturesRating: "8",
  facialSymmetryBalancedFeaturesRatingDescription:
    "Facial features are balanced and harmoniously aligned.",
  facialSymmetryGoldenRatioRating: "7",
  facialSymmetryGoldenRatioRatingDescription:
    "Facial proportions align fairly well with the golden ratio.",
  facialSymmetryHowToImprove:
    "Maintain overall facial health with a balanced diet, regular skin care, and consider cosmetic procedures if desired for further alignment with the golden ratio.",
  cheekbonesCategoryRating: "7",
  cheekbonesHighCheekbonesRating: "8",
  cheekbonesHighCheekbonesRatingDescription:
    "Cheekbones are well-pronounced and defined.",
  cheekbonesSlightlyHollowedCheeksRating: "7",
  cheekbonesSlightlyHollowedCheeksRatingDescription:
    "Cheeks appear slightly hollowed, lending a sculpted look.",
  cheekbonesHowToImprove:
    "Facial exercises can enhance cheekbone definition, and a balanced diet will help maintain a lean face.",
  facialHairCategoryRating: "7",
  facialHairWellGroomedRating: "7",
  facialHairWellGroomedRatingDescription:
    "Facial hair is neat but could be groomed more meticulously.",
  facialHairComplementaryToFaceShapeRating: "8",
  facialHairComplementaryToFaceShapeRatingDescription:
    "Facial hair complements the face shape well.",
  facialHairHowToImprove:
    "Regular trimming and grooming, as well as experimenting with different styles that suit the face shape.",
  bodyFatAndMuscleToneCategoryRating: "8",
  bodyFatLowBodyFatPercentageRating: "8",
  bodyFatLowBodyFatPercentageRatingDescription:
    "Low body fat percentage observed, leading to well-defined facial features.",
  bodyFatTonedMusclesRating: "8",
  bodyFatTonedMusclesRatingDescription:
    "Subtle muscle tone enhances facial structure.",
  bodyFatAndMuscleToneHowToImprove:
    "Maintain a healthy diet rich in protein, and regular exercise to keep body fat percentage low and muscles toned.",
  earsCategoryRating: "7",
  earsSymmetricalEarsRating: "8",
  earsSymmetricalEarsRatingDescription:
    "Ears are symmetrical and evenly positioned.",
  earsProperSizeRating: "7",
  earsProperSizeRatingDescription:
    "Ears are properly sized in relation to the head.",
  earsHowToImprove:
    "No significant improvements needed; maintain overall ear hygiene and health.",
  facialHarmonyCategoryRating: "8",
  facialHarmonyBalancedProportionsRating: "8",
  facialHarmonyBalancedProportionsRatingDescription:
    "Facial proportions are balanced, attributing to overall harmony.",
  facialHarmonyYouthfulAppearanceRating: "8",
  facialHarmonyYouthfulAppearanceRatingDescription:
    "Face maintains a youthful and fresh appearance.",
  facialHarmonyHowToImprove:
    "Continue with a healthy lifestyle, skincare routine, and consider non-invasive cosmetic treatments if desired.",
  overallRating: "8",
  overallHowToImprove:
    "Overall, maintain a balanced and healthy lifestyle, regular exercise, consistent skincare routine, and consider minor cosmetic enhancements if desired.",
};

const tempCurrent = {
  firstName: "dan",
  imageUrl: process.env.REACT_APP_TEMP_IMG,
  id: "17563607-e911-4bfe-9849-395778dca2f7",
};

export default function Home() {
  const [selfieModal, setSelfieModal] = useState(false);
  const [submittingImage, setSubmittingImage] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [showMobileChat, setShowMobileChat] = useState(false);
  const [email, setEmail] = useState("");
  const [submittingEmail, setSubmittingEmail] = useState(false);
  const [currentVotes, setCurrentVotes] = useState({});
  const [votingTimeLimit, setVotingTimeLimit] = useState(false);
  const [onlineUsersCount, setOnlineUsersCount] = useState(0);

  const showMobileChatRef = useRef(showMobileChat);

  useEffect(() => {
    showMobileChatRef.current = showMobileChat;
  }, [showMobileChat]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setShowMobileChat);

  const firstName = Cookies.get("maxx-firstName");
  const imageUrl = Cookies.get("maxx-imageUrl");
  const submissionId = Cookies.get("maxx-submissionId");

  const temp = process.env.REACT_APP_TEMP === "true";

  const [queue, setQueue] = useState<any>(null);
  const [result, setResult] = useState(temp ? tempResults : null);
  const [status, setStatus] = useState<any>(temp ? "live" : null);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [currentAnalysis, setCurrentAnalysis] = useState<any>(
    temp ? tempCurrent : null
  );

  useEffect(() => {
    if (showMobileChat) {
      setUnreadMessagesCount(0);
    }
  }, [showMobileChat]);

  useEffect(() => {
    setVotingTimeLimit(false);
  }, [currentAnalysis]);

  useEffect(() => {
    socket.on("initInfo", (data: any) => {
      if (!temp) {
        setQueue(data.queue);
        setResult(data.result);
        setStatus(data.status);
        setCurrentAnalysis(data.currentAnalysis);
        setMessages(data.chatMessages);
        setCurrentVotes(data.currentVotes);
      }
    });

    socket.on("chatMessage", (message: any) => {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, message];
        if (newMessages.length > 1000) {
          newMessages.shift(); // Remove the oldest message to keep the length at 1000
        }
        return newMessages;
      });
      if (!showMobileChatRef.current) {
        setUnreadMessagesCount((prevCount) => prevCount + 1);
      }
    });

    socket.on("onlineUsers", (count) => {
      setOnlineUsersCount(count);
    });

    socket.on("queueUpdate", (data: any) => {
      setQueue(data.queue);
    });

    socket.on("analysisStarting", (data: any) => {
      setCurrentAnalysis(data);
    });

    socket.on("chatMessageDeleted", ({ id }) => {
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.id !== id)
      );
    });

    socket.on("analysisError", (data: any) => {
      toast.error(
        `${data.firstName} uploaded an invalid image. please ensure there is one face displayed in the image`
      );
      setStatus("idle");
    });

    socket.on("analysisComplete", (data: any) => {
      setResult(data.analysisResult);
      setStatus("live");
    });

    socket.on("statusUpdate", (data: any) => {
      setStatus(data.status);
      if (data.status === "analyzed") {
        setStatus(null);
      }
    });

    socket.on("error", (error: any) => {
      console.error(error.message);
    });

    socket.on("voteUpdate", (data) => {
      setCurrentVotes(data.currentVotes);
    });

    socket.on("finalCrowdRating", (data: any) => {
      toast.success(`🎉 the crowd voted ${data.firstName} ${data.avgVotes}/10`);
    });

    return () => {
      socket.off("queueUpdate");
      socket.off("analysisStarting");
      socket.off("analysisComplete");
      socket.off("analysisError");
      socket.off("statusUpdate");
      socket.off("error");
      socket.off("chatMessage");
      socket.off("voteUpdate");
      socket.off("onlineUsers");
    };
    // eslint-disable-next-line
  }, []);

  const sendMessage = () => {
    if (
      inputValue.trim().length === 0 ||
      inputValue.length > 1000 ||
      !firstName ||
      !imageUrl
    )
      return;

    const message = {
      id: Date.now(),
      content: inputValue,
      firstName,
      imageUrl,
    };

    socket.emit("sendMessage", message);
    setInputValue("");
  };

  const onSubmitImage = async (submissionData: any) => {
    const { image, firstName } = submissionData;
    setSubmittingImage(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/submitImage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            image,
            firstName,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        Cookies.set("maxx-submissionId", result.id, { path: "/" });
        Cookies.set("maxx-firstName", result.firstName, { path: "/" });
        Cookies.set("maxx-imageUrl", result.imageUrl, { path: "/" });
        toast.success("submission successful!");
        setSelfieModal(false);
      } else {
        toast.error("failed to submit. please try again.");
      }
    } catch (error) {
      console.error("error:", error);
      toast.error("an error occurred. please try again.");
    }
    setSubmittingImage(false);
  };

  const handleEmailSubmit = async () => {
    setSubmittingEmail(true);
    const validEmail = email.match(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    );
    if (validEmail) {
      await fetch(`${process.env.REACT_APP_API_URL}/earlyAccessSignUp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });
      toast.success(
        "you're on the early access list! 🎉 look out for an email soon..."
      );
    } else {
      toast.error("enter a valid email");
    }
    setEmail("");
    setSubmittingEmail(false);
  };

  const onSubmitVote = (vote: any) => {
    socket.emit("vote", { vote, submissionId, firstName });
  };

  return (
    <div>
      <SelfieModal
        open={selfieModal}
        onClose={() => setSelfieModal(false)}
        onSubmitImage={onSubmitImage}
        submittingImage={submittingImage}
      />

      <div className="h-[100vh] w-[100vw] dark:bg-black bg-white  dark:bg-dot-white/[0.2] bg-dot-black/[0.2] relative flex items-center justify-center">
        <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>

        <div className="fixed top-0 left-0 p-2 pl-4 sm:p-8 w-full flex justify-start sm:justify-between">
          <p className="text-2xl sm:text-3xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-500">
            agentmaxx.ai
          </p>
        </div>

        <div className="fixed h-[100vh] w-[100vw] pt-[80px] sm:pt-[100px] pb-[104px] sm:pb-0">
          <div className="flex h-full">
            <Queue queue={queue} />
            <div className="flex-1 flex items-center justify-center p-4 sm:p-8 sm:pt-2 w-full h-full">
              {(status === null || status === "idle") && (
                <div className="relative">
                  <HomeAnimation />
                  <div className="flex justify-center">
                    <img
                      src={"/agentmaxx-logo-transparent.png"}
                      alt="agentmaxx"
                      className="w-60 h-60 sm:w-80 sm:h-80"
                    />
                  </div>
                  <p className="text-center text-2xl sm:text-4xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-500 py-8">
                    analyze and improve your facial attractiveness
                  </p>
                  <div className="flex sm:hidden justify-center pb-12">
                    <Button
                      className="font-extrabold text-[15px] sm:text-[18px] w-auto"
                      onClick={() => setSelfieModal(true)}
                    >
                      analyze your face{" "}
                      <ArrowRightCircle className="ml-1 h-[18px] w-[18px] sm:ml-4 sm:w-[20px] sm:h-[20px]" />
                    </Button>
                  </div>
                </div>
              )}

              {status === "analyzing" && currentAnalysis && (
                <div className="w-full h-full">
                  <div className="flex flex-col h-full">
                    {/* Background image div */}
                    <div className="relative flex-grow">
                      <div
                        className="absolute inset-0 bg-cover bg-center"
                        style={{
                          backgroundImage: `url(${currentAnalysis?.imageUrl})`,
                        }}
                      ></div>
                      <div
                        className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black opacity-100"
                        style={{ top: isMobile ? "95%" : "50%" }}
                      ></div>
                      <div className="block sm:hidden absolute bottom-3 right-1">
                        {onlineUsersCount && onlineUsersCount > 0 ? (
                          <div
                            className="absolute top-[-16px] left-0 w-full flex sm:hidden justify-center items-center font-bold text-[9.5px]"
                            style={{
                              textShadow: "0 0 10px rgba(0, 0, 0, 0.8)",
                            }}
                          >
                            <div className="rounded-full h-1 w-1 bg-green-500 mr-1" />
                            {onlineUsersCount} in chat
                          </div>
                        ) : (
                          <></>
                        )}
                        <Button
                          variant="outline"
                          className="bg-black bg-opacity-70 h-9 w-32 relative"
                          onClick={() => setShowMobileChat(!showMobileChat)}
                        >
                          chat{" "}
                          <MessageSquare
                            fill="white"
                            className="w-4 h-4 ml-2"
                          />
                          {unreadMessagesCount && unreadMessagesCount > 0 ? (
                            <div className="py-0 px-1.5 bg-red-500 font-bold text-white text-[10px] absolute top-[-8px] right-[-4px] rounded-full">
                              {unreadMessagesCount > 99
                                ? "99+"
                                : unreadMessagesCount}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="flex items-center justify-center">
                      <Loader2 className="animate-spin w-6 h-6 sm:w-8 sm:h-8 mr-3 sm:mr-4" />
                      <p className="text-center text-xl sm:text-4xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-500 py-16">
                        {currentAnalysis?.firstName} is currently being{" "}
                        {!isMobile && <br />}
                        analyzed by agentmaxx
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {status === "live" && result && (
                <Results
                  currentAnalysis={currentAnalysis}
                  result={result}
                  showMobileChat={showMobileChat}
                  setShowMobileChat={setShowMobileChat}
                  queue={queue}
                  votingTimeLimit={votingTimeLimit}
                  setVotingTimeLimit={setVotingTimeLimit}
                  currentVotes={currentVotes}
                  onlineUsersCount={onlineUsersCount}
                  unreadMessagesCount={unreadMessagesCount}
                />
              )}
            </div>

            <Chat
              messages={messages}
              imageUrl={imageUrl}
              firstName={firstName}
              sendMessage={sendMessage}
              inputValue={inputValue}
              setInputValue={setInputValue}
              submissionId={submissionId}
              currentVotes={currentVotes}
              currentAnalysis={currentAnalysis}
              status={status}
              onSubmitVote={onSubmitVote}
              votingTimeLimit={votingTimeLimit}
              onlineUsersCount={onlineUsersCount}
            />
          </div>
        </div>
        <div className="fixed top-[50px] bottom-auto sm:top-0 sm:bottom-auto left-0 flex justify-center w-screen p-0 px-4 sm:p-8 sm:px-8">
          {currentAnalysis?.id === submissionId &&
          (status === "analyzing" || status === "live") ? (
            <Button
              disabled
              variant="outline"
              className="font-extrabold text-base sm:text-lg text-[15px] sm:text-[18px] h-8 sm:h-auto w-full sm:w-auto"
            >
              you are currently being analyzed
            </Button>
          ) : queue &&
            submissionId &&
            queue.some((item: any) => item.id === submissionId) ? (
            <Button
              disabled
              variant="outline"
              className="font-extrabold text-base sm:text-lg text-[15px] sm:text-[18px] h-8 sm:h-auto w-full sm:w-auto"
            >
              your submission is
              <Badge className="mx-2">
                #{queue.findIndex((item: any) => item.id === submissionId) + 1}
              </Badge>
              in line
            </Button>
          ) : (
            <Button
              className={`${
                status === "live" || status === "analyzing" ? "flex" : "hidden"
              } sm:flex font-bold sm:font-extrabold text-[15px] sm:text-[18px] h-8 sm:h-auto w-full sm:w-auto`}
              onClick={() => setSelfieModal(true)}
            >
              analyze your facial attractiveness{" "}
              <ArrowRightCircle className="ml-1 h-[18px] w-[18px] sm:ml-4 sm:w-[20px] sm:h-[20px]" />
            </Button>
          )}
        </div>
        <div className="fixed top-2 right-4 sm:top-8 sm:right-8">
          <Dialog>
            <DialogTrigger>
              <Button variant="outline" className="px-3 sm:px-4 h-8 sm:h-auto">
                <ScanFaceIcon className="w-4 h-4 mr-1.5 sm:mr-2" /> early access
              </Button>
            </DialogTrigger>
            <DialogContent className="max-h-[90vh] max-w-[100vw] sm:max-w-[90vw] overflow-hidden p-0 sm:p-6">
              <div className="p-0 sm:p-8">
                <div className="flex justify-center">
                  <div className="text-xl sm:text-4xl font-bold p-4 sm:p-0 flex items-start">
                    <div className="mr-2">🔓</div>
                    <div>
                      create an account to {isMobile && <br />}
                      get <u>early access</u> to
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <EarlyAccessModal />
                </div>
                <div className="mt-4 sm:mt-16 p-4 sm:p-0">
                  <div className="flex justify-center">
                    <Input
                      id="emailInput"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={"enter your email..."}
                      className="text-[16px] mt-1 w-full sm:w-80"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleEmailSubmit();
                        }
                      }}
                    />
                  </div>
                  <div className="flex justify-center mt-4">
                    <Button
                      onClick={handleEmailSubmit}
                      disabled={submittingEmail}
                      className="w-full sm:w-80"
                    >
                      {submittingEmail ? (
                        <Loader2 className="w-4 h-4 animate-spin" />
                      ) : (
                        <div className="flex items-center">
                          get early access{" "}
                          <ArrowRightCircle className="h-4 w-4 ml-2" />
                        </div>
                      )}
                    </Button>
                  </div>
                  <Separator className="mt-8 mb-4 sm:mb-8" />
                  <div className="flex justify-between sm:justify-end">
                    <Link
                      to={"https://discord.gg/UEsZMpgVCg"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="bg-[#7289da] flex items-center">
                        <img
                          src={"discord.png"}
                          alt="discord"
                          className="w-4 h-4 mr-2"
                        />
                        join the discord
                      </Button>
                    </Link>
                    <Link
                      to="https://www.instagram.com/dan.halperin_/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button variant="link">
                        built for fun 🌐 by{" "}
                        <span className="underline ml-1">me</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div ref={wrapperRef}>
        {showMobileChat && (
          <Chat
            messages={messages}
            imageUrl={imageUrl}
            firstName={firstName}
            sendMessage={sendMessage}
            inputValue={inputValue}
            setInputValue={setInputValue}
            mobile
            setShowMobileChat={setShowMobileChat}
            currentVotes={currentVotes}
            submissionId={submissionId}
            currentAnalysis={currentAnalysis}
            status={status}
            onSubmitVote={onSubmitVote}
            votingTimeLimit={votingTimeLimit}
            onlineUsersCount={onlineUsersCount}
          />
        )}
      </div>
      <div
        className={`${
          status === "idle" || status === null ? "flex" : "hidden"
        } sm:flex fixed bottom-0 left-0 w-full flex justify-center`}
      >
        <Link
          to="https://www.instagram.com/dan.halperin_/"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="link" className="p-0 h-2">
            built for fun 🌐 by <span className="underline ml-1">me</span>
          </Button>
        </Link>
      </div>
    </div>
  );
}

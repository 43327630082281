import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";

let userId = Cookies.get("maxx-userId");
if (!userId) {
  const newUserId = uuidv4();
  Cookies.set("maxx-userId", newUserId);
}

export const socket = io(
  process.env.REACT_APP_API_URL || "http://localhost:8080",
  {
    autoConnect: true,
    query: { userId },
  }
);
